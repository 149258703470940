import { render, staticRenderFns } from "./Briefing.vue?vue&type=template&id=6c7b0d00&scoped=true&"
import script from "./Briefing.vue?vue&type=script&lang=js&"
export * from "./Briefing.vue?vue&type=script&lang=js&"
import style0 from "./Briefing.vue?vue&type=style&index=0&id=6c7b0d00&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c7b0d00",
  null
  
)

export default component.exports